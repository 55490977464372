import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "set-team-context-per-page"
    }}>{`Set team context per page`}</h1>
    <p>{`Setting team context per site
You can add `}<inlineCode parentName="p">{`context`}</inlineCode>{` to the script as a string that represents the team name to which team you wish to launch the chat. Context in script needs to be kept in sync with name of the teams in Smilee service.
Using this way to set context makes it possible to run different teams in different parts of the website.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Setting this property will override primary team`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<!-- Smilee service script start -->
<script type="text/javascript" charset="UTF-8" async>
  (function () {
    var x = document.createElement('script');
    x.src = 'https://saas.smilee.io/assets/javascripts/cobrowse.js';
    x.type = 'text/javascript';
    x.async = 'true';
    x.onload = x.onreadystatechange = function () {
      var rs = this.readyState;
      if (rs && rs != 'complete' && rs != 'loaded') return;
      try {
        Cobrowse.create({
          apiKey: "LQt/yocAfcWRAt...",
          context: 'My awesome team'
        });
      } catch (e) {
        console.error('Failed loading Smilee script');
      }
    };
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(x, s);
  })();
</script>
<!-- Smilee service script end -->
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      